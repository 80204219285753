@import '~antd/dist/antd.less';

@primary-color: #009cda; // primary color for all components
@link-color: #009cda; // link color
@success-color: #52c41a; // success state color
@warning-color: #faad14; // warning state color
@error-color: #f5222d; // error state color
@font-size-base: 14px; // major text font size
@heading-color: #262626; // heading text color
@text-color: #595959; // major text color
@text-color-secondary: rgba(0, 0, 0, 0.45); // secondary text color
@disabled-color: rgba(0, 0, 0, 0.25); // disable state color
@border-radius-base: 2px; // major border radius
@menu-icon-margin-right: 10px;

#root {
  height: 100%;
  @media (max-width: 640px) {
    .ant-layout {
      background: #ffffff;
    }
  }
}

body {
  background: #ffffff;

  @media (min-width: 640px) {
    background: #f0f2f5;
  }

  input[type='number']::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }
  input[type='number'] {
    -moz-appearance: textfield;
    appearance: textfield;
  }
}

tr.drop-over-downward td {
  border-bottom: 2px dashed #1890ff;
}

tr.drop-over-upward td {
  border-top: 2px dashed #1890ff;
}

.modal.hide-icon .ant-modal-confirm-body > .anticon {
  display: none;
}

.modal.hide-icon
  .ant-modal-confirm-body
  > .anticon
  + .ant-modal-confirm-title
  + .ant-modal-confirm-content {
  margin-left: 0;
}

::-webkit-scrollbar-track {
  background-color: #fff;
}

::-webkit-scrollbar {
  width: 10px;
  height: 10px;
  background: #fff;
}

::-webkit-scrollbar-thumb {
  background: #848484;
  border-radius: 4px;
  border: 1px #fff solid;
}

@primary-color: #009cda;@link-color: #009cda;@success-color: #52c41a;@warning-color: #faad14;@error-color: #f5222d;@font-size-base: 14px;@heading-color: #262626;@text-color: #595959;@text-color-secondary: rgba(0, 0, 0, 0.45);@disabled-color: rgba(0, 0, 0, 0.25);@border-radius-base: 2px;@menu-icon-margin-right: 10px;